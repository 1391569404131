import React from 'react'
import { onError } from '@apollo/client/link/error'
import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache, split } from '@apollo/client'
import { JwtPayload } from 'jwt-decode'
import decodeJWT from 'jwt-decode'
import { APP_NAME } from '../consts/StringConst'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getLogger } from '../utils/Logger'
import { ERROR } from 'consts/LogSubjectConst'

const log = getLogger()

export type Headers = {
    authorization?: string
    'x-hasura-role'?: string
}

class AccessTokenAndJWT {
    private readonly expirationTimeInSeconds: number

    constructor(public readonly accessToken: string | null) {
        if (accessToken == null) {
            this.expirationTimeInSeconds = 0
        } else {
            const claims: JwtPayload = decodeJWT(accessToken)
            this.expirationTimeInSeconds = (claims.exp ?? 0) * 1000
        }
    }

    isValid(date: Date): boolean {
        if (this.expirationTimeInSeconds === 0) {
            return false
        }
        return this.expirationTimeInSeconds >= date.getTime()
    }

    updateTime(): number {
        const expired = this.expirationTimeInSeconds
        const current = Date.now()
        // 有効期間の9割の時間経過後にrefresh
        let refresh = ((expired - current) * 9) / 10
        if (refresh < 0) {
            // 既に有効期限切れの場合はrefreshに0を設定
            refresh = 0
        }
        log.info(`\n expired: ${expired}\n current: ${current}\n refresh: ${refresh}`)
        return refresh
    }
}

let accessTokenAndJWT = new AccessTokenAndJWT(null)
let tokenExpiredHandler: number | undefined = undefined

const rawRequestAccessToken = async () => {
    clearTimeout(tokenExpiredHandler)

    const res = await fetch('/api/auth/session')
    if (res.ok) {
        const json = await res.json()
        accessTokenAndJWT = new AccessTokenAndJWT(json.accessToken)
        log.info("accessToken (Show only when debugging)", accessTokenAndJWT.accessToken)

        const delay = accessTokenAndJWT.updateTime()
        if (delay !== 0) {
            tokenExpiredHandler = window.setTimeout(() => {
                rawRequestAccessToken()
            }, delay)
        }
    } else {
        // accessTokenAndJWT = new AccessTokenAndJWT(null) を設定していたが、
        // AccessTokenAndJWTのコンストラクタ引数のaccessTokenがnullだと、createWebSocketLinkで設定しているwebSocketLintのauthorizationが空文字となりhasuraで "Malformed Authorization header" エラーとなる。
        // そうなった場合は現在のページをリロードしてセッションを取り直す
        log.warn('/api/auth/session response is not ok.')
        window.location.reload()
    }
}

const requestAccessToken = async () => {
    if (accessTokenAndJWT.isValid(new Date())) {
        return
    }

    return rawRequestAccessToken()
}

type Props = {
    children?: React.ReactNode
}
const AuthorizedApolloProvider: React.FC<Props> = ({ children }) => {
    const ssrMode = typeof window === 'undefined'

    const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach((graphQLError) => {
                // TODO: token expired

                log.error(ERROR.GRAPHQL, graphQLError)
            })
        }

        if (networkError) {
            log.error(ERROR.NETWORK, networkError?.message)
        }

        return forward(operation)
    })

    const createHttpLink = (uri: string, headers: Headers) => {
        return new HttpLink({
            uri: uri,
            credentials: 'include',
            headers, // auth token is fetched on the server side
            fetch,
        })
    }
    const createWebSocketLink = (uri: string, headers: Headers) => {
        return new GraphQLWsLink(
            createClient({
                url: uri.replace(/http:\/\//, 'ws://').replace(/https:\/\//, 'wss://'),
                lazy: true,
                connectionParams: async () => {
                    await requestAccessToken() // happens on the client
                    return {
                        headers: {
                            authorization: accessTokenAndJWT.accessToken
                                ? `Bearer ${accessTokenAndJWT.accessToken}`
                                : '',
                            ...headers,
                        },
                    }
                },
            })
        )
    }
    const createLink = (ssrMode: boolean, uri: string, headers: Headers) => {
        if (ssrMode) {
            return createHttpLink(uri, headers)
        } else {
            return createWebSocketLink(uri, headers)
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const linkAdmin = createLink(ssrMode, process.env.NEXT_PUBLIC_GRAPHQL_URI!, {
        'x-hasura-role': 'admin',
    })
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const linkUser = createLink(ssrMode, process.env.NEXT_PUBLIC_GRAPHQL_URI!, {
        'x-hasura-role': 'user',
    })
    const link = split(
        (operation) => operation.getContext().hasuraRole === 'admin',
        linkAdmin,
        linkUser
    )
    const cache = new InMemoryCache({
        addTypename: false,
    })
    const apolloClient = new ApolloClient({
        ssrMode,
        link: from([errorLink, link]),
        cache: cache,
        name: `${APP_NAME}/react-web-client`,
        version: '1.0.0',
        queryDeduplication: false,
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'no-cache',
            },
        },
        connectToDevTools: process.env.NODE_ENV !== 'production',
    })

    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default AuthorizedApolloProvider
