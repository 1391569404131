import { ReactNode } from "react"
import { colors, createTheme, CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import ja from "dayjs/locale/ja"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"

/** FontFamily */
export const FONT_FAMILY = [
    "Hiragino Maru Gothic ProN",
    "Hiragino Kaku Gothic ProN",
    "Meiryo",
    "sans-serif",
].join(",")

/** アプリケーションテーマプロバイダーProps */
type AppThemeProviderProps = {
    /** 子ノード */
    children: ReactNode
}

/**
 * アプリケーションテーマプロバイダー
 *
 * {@link theme テーマ定義}をchildrenに適用する
 * @param props {@link ThemeProviderProps}
 */
const AppThemeProvider = (props: AppThemeProviderProps) => {
    const {children} = props

    const isMatchedMinWidth600 = useMediaQuery("(min-width:600px)")
    const isMatchedMinHeight600 = useMediaQuery("(min-height:600px)")

    /**
     * - true: ウィンドウサイズが小さい(横幅または縦幅が600px未満)
     * - false: ウィンドウサイズが大きい(横幅および縦幅が600px以上)
     */
    const isSmallWindow = !(isMatchedMinWidth600 && isMatchedMinHeight600)

    /** テーマ定義（スタイル定義） */
    const theme = createTheme({
        palette: {
            primary: {
                main: colors.common["white"],
            },
            mode: "dark",
        },
        typography: {
            button: {
                // ボタンのラベルが大文字になるのを防ぐ
                textTransform: "none",
            },
            fontFamily: FONT_FAMILY,
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: (_themeParam) => ({
                    // 全体
                    "*": {
                        boxSizing: "borderBox",
                        fontFamily: FONT_FAMILY,
                        wordBreak: "break-all",
                    },
                    // html
                    "html": {
                        width: "100%",
                        height: "100%",
                        padding: 0,
                        margin: 0,
                    },
                    // body
                    "body": {
                        padding: 0,
                        margin: 0,
                    },
                    // aタグ
                    "a": {
                        color: "inherit",
                        textDecoration: "none",
                    },
                    // スクロールバー: 全体
                    "::-webkit-scrollbar": {
                        width: `${isSmallWindow ? 2 : 16}px`,
                        height: `${isSmallWindow ? 2 : 16}px`,
                    },
                    // スクロールバー: 摘み（スライドさせるバー部分）
                    "::-webkit-scrollbar-thumb": {
                        backgroundColor: "rgb(255, 255, 255, 0.8)",
                        backgroundClip: "padding-box",
                        border: `${isSmallWindow ? 0 : 4}px solid transparent`,
                        borderRadius: "8px",
                    },
                    // スクロールバー: トラック（摘みをスライドさせるレール部分）
                    "::-webkit-scrollbar-track": {
                        margin: "4px",
                    },
                    // スクロールバーの終端（上下左右が交差する点）
                    "::-webkit-scrollbar-corner": {
                        display: "none",
                    },
                })
            },
        },
    })

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={ja.name}
                dateFormats={{ monthAndYear: "YYYY/MM", year: "YYYY" }}
                localeText={{
                    timePickerToolbarTitle: "",
                    datePickerToolbarTitle: "",
                    dateRangePickerToolbarTitle: "",
                    dateTimePickerToolbarTitle: "",
                    okButtonLabel: "選択",
                    cancelButtonLabel: "キャンセル",
                    previousMonth: "前月",
                    nextMonth: "次月",
                }}
            >
                {children}
            </LocalizationProvider>
        </ThemeProvider>
    )
}

export default AppThemeProvider
